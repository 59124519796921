* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
}

.title {
  text-align: center;
  font-size: xx-large;
}

/* CardFilter.css */

/* CardFilter.css */

.cardFilter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.cardFilter .advanced,
.cardFilter .attack_health_mana {
  display: flex;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  margin: 0.5%;
}

/* Adjust the width of the Search bar */
.cardFilter .ant-input-search {
  width: 20rem;
}

/* Media query for mobile view */
@media (max-width: 767px) {
  .cardFilter .advanced {
    flex-direction: column;
  }
}

.cardPagination {
  /* padding: 0rem 0.25rem 0.25rem 20rem; */
  justify-content: center;
  align-items: center;
}

.loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  height: 700px;
  text-align: center;
  border-radius: 4px;
}

.cardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0rem;
}

.error {
  text-align: center;
  font-size: x-large;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
